import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide61/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide61/image/img.png"
import slider1 from "@components/pageGuide/guides/guide61/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide61/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide61/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    6 tipos de clientes inmobiliarios y cómo generar conversiones 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    El mercado inmobiliario es diverso y dinámico, con una variedad de clientes que tienen diferentes necesidades y expectativas. Identificar y comprender estos distintos tipos de clientes es esencial para desarrollar estrategias de ventas efectivas y maximizar las conversiones.
     </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
En esta guía, te guiaremos a través de los seis tipos de clientes inmobiliarios más comunes y te proporcionaremos técnicas para convertir cada uno de ellos.  
<br /><br />
Al conocer las características y comportamientos de tus clientes, podrás personalizar tu enfoque y mejorar significativamente tus resultados de ventas.
</p>
)

const data = {
  start: {
    support: "Guía | Inmobiliarias",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Análisis de clientes en el sector.",
      },
      {
        key: 2,
        text: "Estrategia para inversores.",
      },
      {
        key: 3,
        text: "Uso de WhatsApp para ventas.",
      },
      {
        key: 4,
        text: "Implementar estrategias de email marketing.",
      },
      {
        key: 5,
        text: "Comunicación de promociones vía SMS.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
