import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide61/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        6 tipos de clientes inmobiliarios y cómo generar conversiones
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/tipos-clientes-inmobiliarios/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre los 6 tipos de clientes inmobiliarios y aprende estrategias efectivas para generar conversiones en el competitivo mercado de bienes raíces."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="6 tipos de clientes inmobiliarios y cómo generar conversiones"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/tipos-clientes-inmobiliarios/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia61.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre los 6 tipos de clientes inmobiliarios y aprende estrategias efectivas para generar conversiones en el competitivo mercado de bienes raíces."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Inmobiliarias"
      location={location}
      typeGuide="guide61"
      clickHereHref="https://beexcc.com/blog"
      data={data}
    />
  </div>
)

export default IndexPage
